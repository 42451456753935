.contentMap{
    flex-direction: row;
    display: flex;
    /*background-color: red;*/
    min-height: 95vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../img/FONDO-01.jpg');
}
.formatInfoMarker{
    padding: 25px;
}
#map-page{
    width:50vw;
}
