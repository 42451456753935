/*blankPage*/

#fondoChat {
  background-size: contain;
  min-width: 100%;
  /* height: 500px; */
}

#fondoPicBlank {
  height: 380px;
}

.margin-icons {
  margin-right: 10px;
  color: #a3a3a9;
}

.spaceBtnname {
  position: relative;
  left: 40%;
}

/*chatBorard*/

.bodyScreenChatRow {
  flex-direction: row;
  display: flex;
  background-color: red;
  min-height: 95vh;
}

.viewListUserChats {
  overflow-y: scroll;
  max-height: 95vh;
  background-color: #ffffff;
  flex: 1;
  min-width: 25%;
  max-width: 25%;
}

.sizeListChatUser {
  overflow-y: scroll;
  max-height: 60vh;
}

.viewBoradChat {
  background-color: rgb(235, 225, 225);
  max-width: 75%;
  min-width: 75%;
  max-height: 95vh;
  background-image: url("../img/FONDO-01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

/*scroll*/
.viewListUserChats::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: #e8e8e8;
}

.viewListUserChats::-webkit-scrollbar {
  width: 6px;
}

.viewListUserChats::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #203152;
}

/*sizeCardItem*/
.sizeCardItem {
  width: 100%;
  max-width: 100%;
}

.icoPicture {
  border-radius: 100% !important;
  width: 55px;
  height: 55px;
  object-fit: cover;
  object-position: center;
}

.paddinUserInfo {
  padding: 10px 0;
  margin-bottom: 0px !important;
  background-color: #5e5e68;
}

/*ChatMain*/
.viewChatBoard {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: 95vh;
  max-height: 95vh;
}

.headerChatBoard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 70px;
  background: #dddddf;
  /*background: linear-gradient(
    45deg, #80D0C7, #0093E9);*/
}

.headerTextChat {
  position: relative;
  top: 15%;
  flex: 2.5;
}

.viewListContentChat {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.viewListContentChat::-webkit-scrollbar-track {
  padding: 2px 0;
}

.viewListContentChat::-webkit-scrollbar {
  width: 6px;
}

.viewListContentChat::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e8e8e8;
}

.viewListContentChat div:last-child {
  margin-bottom: 10px;
}

.viewBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-top: 1px solid #e8e8e8;
  width: 100%;
  background-color: #dbdada;
}

input[type="text"] {
  border-bottom: none !important;
  outline: none !important;
}

.inputMessage {
  background: white;
  color: #75747d;
  border-radius: 12px;
  padding: 0px 10px 0px 10px;
  text-align: center;
  flex: 1;
  height: 40px;

  font-family: "Roboto", sans-serif !important;
  text-decoration-color: #75747d;
}

.iconFormMsn {
  position: relative;
  top: 3px;
}

.txtAreaBorder-None {
  border: none;
  color: #75747d;
  font-family: "Roboto";
  padding: 11px 5px;
  overflow: auto;
  outline: none;
  resize: none;
}

.txtAreaGroup {
  resize: none;
}

::placeholder {
  color: #75747d;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #75747d;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #75747d;
}

/*
  MESSAGES STYLE 
  */

.MessageBox {
  float: left;
  width: 98%;
  margin: 5px 0 0 2%;
  font-family: "Roboto", sans-serif;
  color: #75747d;
}

.MessageBox .ChatMessage {
  width: 80%;
  min-height: 40px;
  font-family: "Roboto", sans-serif;
  color: #75747d;
}

.MessageBox .ChatMessage .RightBubble {
  position: relative;
  /*background: #ffe6d7;*/
  background: linear-gradient(45deg, #5bc2b6, #0377b8);
  border-top-left-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  padding: 5px 10px 10px;
  left: 70%;
  width: 50%;
  width: 50%;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.MessageBox .ChatMessage .RightBubble span.MsgName {
  font-size: 12px;
  font-weight: bold;
  color: green;
  font-family: "Roboto", sans-serif;
}

.MessageBox .ChatMessage .RightBubble span.MsgDate {
  font-family: "Roboto", sans-serif;
  display: block;
  /* background: red; */
  width: auto;
  font-size: 8pt;
  font-weight: 600;
  /*color: grey;*/
  color: white;
}

.MessageBox .ChatMessage .RightBubble:after {
  content: "";
  position: absolute;
  right: 0;
  top: 13px;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  /*border-left-color: #ffe6d7;*/
  border-left-color: #0377b8;
  border-right: 0;
  border-top: 0;
  margin-top: -13.5px;
  margin-right: -27px;
  font-family: "Roboto", sans-serif;
  /*color:#75747D;*/
  color: white;
}

.MessageBox .ChatMessage .LeftBubble {
  position: relative;
  background: #ffffff;
  border-top-right-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  padding: 5px 10px 10px;
  left: 5%;
  width: 50%;
  width: 50%;
  font-family: "Roboto", sans-serif;
  color: #222222;
}

.MessageBox .ChatMessage .LeftBubble span.MsgName {
  font-size: 12px;
  font-weight: bold;
  color: rgb(30, 30, 31);
  font-family: "Roboto", sans-serif;
}

.MessageBox .ChatMessage .LeftBubble span.MsgDate {
  font-size: 8pt;
  font-weight: 600;
  color: grey;
}

.MessageBox .ChatMessage .LeftBubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  width: 0;
  height: 0;
  border: 27px solid transparent;
  border-right-color: #ffffff;
  border-left: 0;
  border-top: 0;
  margin-top: -13.5px;
  margin-left: -27px;
  font-family: "Roboto", sans-serif;
  color: #75747d;
}

.MessageBox .ChatStatus {
  min-height: 49px;
  font-family: "Roboto", sans-serif;
}

.MessageBox .ChatStatus .ChatDate {
  display: block;
  font-size: 10px;
  font-style: italic;
  color: #777;
  height: 15px;
  left: 10%;
  right: 10%;
  font-family: "Roboto", sans-serif;
}

.MessageBox .ChatStatus .ChatContentCenter {
  padding: 5px 10px;
  background-color: #e1e1f7;
  border-radius: 6px;
  font-size: 12px;
  color: #555;
  height: 34px;
  left: 10%;
  right: 10%;
  font-family: "Roboto", sans-serif;
}

.icOpenSticker {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
}

.icOpenGallery {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.bubbleCounter {
  display: inline-block;
  background: #6fc7c3;
  color: white;
  width: 15px;
  font-size: 10pt;
  border-radius: 100%;
  height: 15px;
}

/*=============================*/
/*IMagen CHAT send*/
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modalPic {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  min-height: 100vh;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-contentPic {
  margin: auto;
  display: block;
  width: 50%;
  max-width: 650px;
  max-height: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-contentPic,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-contentPic {
    width: 100%;
  }
}

/*=============================*/
/*checks list*/
.ck1 {
  background-color: white !important;
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  top: 10px;
  left: 5px;
}

.ckOp2 {
  background-color: white !important;
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  top: 10px;
  left: 5px;
  left: 20% !important;
}

.activeSecondary {
  color: #91dd59;
}

.activePrimary {
  color: #ff9966;
}

.serachChat {
  width: 85%;
  background: white;
  border-radius: 15px;
  height: 44px !important;
  font-family: "Roboto", sans-serif;
  margin: 0px auto !important;
}

.chatImgLocations {
  width: 100%;
}


.sizeCheckBitacora {
  width: 15px;
  height: 15px;
}

.ckBAll {
  background-color: white !important;
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  top: 10px;
  left: 5px;
}