
.formInputPass {
    background: white !important;
    border-radius: 5px;
    color: black;
   margin: 0 auto;
   margin-bottom: 10px;    
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    width: 300px;
    height: 42px;
    font-family: 'Roboto', sans-serif !important;
    text-decoration-color: black;
}

.formInputSelect {
    background: white !important;
    border-radius: 5px;
    color: black;
   margin: 0 auto;
   margin-bottom: 10px;    
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    width: 300px;
    height: 45px;
    font-family: 'Roboto', sans-serif !important;
    text-decoration-color: black;
}


.profilePicture{
    border-radius: 100% !important;
    width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
    
}

.contentFormProfile {
    /* background: red; */
    height: auto;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    font-family: 'Roboto', sans-serif;
}

.noBorderSelect{
    border: none !important;

}
