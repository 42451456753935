.containerEvid {
  width: 100%;
  min-height: 100vh;
  background-image: url('../img/FONDO-01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.inputSerach {
  width: 70%;
  background: white;
  border-radius: 15px;
  height: 50px !important;
  font-family: 'Roboto', sans-serif;
  margin: 0px auto !important;
}

.inputDate {
  background: white;
  border-radius: 15px;
  height: 50px !important;
  font-family: 'Roboto', sans-serif;
  margin: 0px auto !important;
}

.datepicker-date-display {
  background-color: #48484b !important;
}

.inputSerachBorderBottom {
  border-bottom: none !important;
}

.tableFont {
  font-family: 'Roboto', sans-serif;
}

.optionsEvi {
  display: flex;
  /* justify-content: space-between;*/
  flex-direction: row;
}

.contentTable {
  width: 90%;
  margin: 0 auto;
}

/*Content Form & select area*/

.input-field {
  border-bottom: none !important;
}

.containerIfo {
  margin: 0 auto;
  width: 95%;
  min-height: 95vh;
  background-image: url('../img/FONDO-01.jpg');
  background-repeat: no-repeat;
  background-size: cover;

}

.fondInfo {
  background-image: url('../img/FONDO-01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.contentInfo {
  display: flex;
  height: 100%;
  flex-direction: row;
  padding-top: 2%;
}

.colorone {

  width: 100%;
  height: 95vh;

}

.colortwo {
  /* background-color: rgb(77, 53, 53);*/
  width: 100%;
  height: 95vh;
}

.inputMessage-evidence {
  background: #ffffff;
  color: #48484b;
  border-radius: 12px;
  padding: 0px 10px 0px 10px;
  text-align: center;
  flex: 1 1;
  height: 250px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration-color: #6d6d6d;
  font-size: 14pt;
  font-weight: 600;
}

.globalFormEviInc {
  background: #ffffff;
  color: #48484b;
  border-radius: 12px;
  padding: 0px 10px 0px 10px;
  text-align: center;
  flex: 1 1;
  height: 50px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration-color: #6d6d6d;
  font-size: 14pt;
  font-weight: 600;

}

.txtAreaBorder-None-eviden {
  border: none;
  color: #75747D;
  font-family: 'Roboto';
  padding: 11px 5px;
  overflow: auto;
  outline: none;
  height: 250px;

}

.btnEnviarEmail {
  position: relative;
  left: 70%;
}

#backP {
  margin-left: 10px;
  width: 22%;
}

#sendEmail {
  background: linear-gradient(45deg, #5BC2B6, #0377B8);
  width: 22%;
}

@media only screen and (max-width: 371px) {
  #backP {
    margin-bottom: 10px;
  }
}

.contenGrid {
  height: 450px;

  overflow-y: scroll;
}


#search::-webkit-input-placeholder {

  font-weight: bold;
}

#rowHighLigth {
  background-color: #DDDDDF;
}

.borderbotomLine {
  border-bottom: 2px solid black;
}

.ck1 {
  display: block;

}

.ck2 {
  display: block;

  left: 20% !important;
}

.sizetallImg {
  height: 300px !important;
}

.iconsList {
  color: #5b7fb5;
}

/*new evidences*/
.newsEvidences {
  background-color: #92f572 !important;
  color: black;
  font-weight: 700;
}

.divSpaceOptin {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  flex-direction: row;
}

.inputPage {
  border-radius: 5px;
  color: black;
  margin: 0 auto;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  /* width: 20%; */
  height: 46px;
  font-family: 'Roboto', sans-serif !important;
  text-decoration-color: black;
  background-color: #ffffff !important;
  position: relative;
  top: 7px;
}

.contentPagination {
  width: 25%;
  /* background: red; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 auto;
}

@media only screen and (max-width: 500px) {
  .contentPagination {
    width: 100%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1100px) {
  .contentPagination {
    width: 10%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  .contentPagination {
    width: 30%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }
}

@media only screen and (width: 700px) {
  .contentPagination {
    width: 30%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }
}

@media only screen and (width: 400px) {
  .contentPagination {
    width: 30%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }
}

@media (min-width: 650px) and (max-width: 700px),
(min-width: 750px) and (max-width: 800px),
(min-width: 850px) {
  .contentPagination {
    width: 30%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }

}

@media (min-width: 300px) and (max-width: 450px),
(min-width: 600px) and (max-width: 750px),
(min-width: 850px) {
  .contentPagination {
    width: 30%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }

}

@media only screen and (max-width: 960px) {
  .contentPagination {
    width: 30%;
    /* background: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
  }
}

.deText {
  display: block;
  position: relative;
  font-size: 12;
  font-size: 15pt;
  top: 15px;
  /* width: 20%; */
  color: black;
}

.leftHead {
  text-align: left !important;
}

.leftContent {
  text-align: left !important;
}

.noResize {
  resize: none !important;
}