.Container {
  background-color: rgb(144, 80, 196);
  width: 100%;
  height: 100vh;
  background-image: url("../img/HOME-01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contentForm {
  /*background: red;*/
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  font-family: "Roboto", sans-serif;
}

.formInput {
  border-radius: 5px;
  color: black;
  margin: 0 auto;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: 300px;
  height: 42px;
  font-family: "Roboto", sans-serif !important;
  text-decoration-color: black;
}

/*.iconPositionInput{
    color: grey;
    top: 0.7rem !important;
    font-size: 1.4rem !important;
}*/

.checkBoxRemainder {
  text-align: left;
  /*margin-left: 10%;*/
  margin-top: -18px;
}

.checkBoxRemainder span {
  color: white;
  font-size: 8pt !important;
  font-family: "Roboto", sans-serif;
}

.checkbox-orange[type="checkbox"].filled-in:checked+label:after {
  border: 2px solid #ff9800 !important;
  background-color: #ff9800 !important;
}

.input-field {
  border-bottom: none !important;
}

[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
  border-color: white;
  border-radius: 5px;
  background-color: white !important;
  box-shadow: 2px 2px 2px gray;
}

.btnFromLogin {
  background-color: #ff9966;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: none !important;
}

.btnBitacora {
  width: 55%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: none !important;
}

.btnFromLoginCancel {
  background-color: #bd5521;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: none !important;
}

.registerDiv {
  margin-top: 35px;
}

.grettingsDiv {
  margin-bottom: 12%;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
}

.grettingsDiv :first-child {
  font-size: 20pt;
  font-weight: 700;
  display: block;
}

.grettingsDiv :last-child {
  margin-top: -5px;
  font-size: 8pt;
  font-weight: 200;
  display: block;
}

.logoAppDiv {
  height: 120px;
  width: 100%;
  background-color: transparent;
  margin-bottom: 25%;
  background-image: url("../img/DRIVELOG.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
}

input[type="text"] {
  border-bottom: none !important;
  outline: none !important;
}

.sizeInput {
  height: 40px !important;
}