.Container {
  background-color: rgb(144, 80, 196);
  width: 100%;
  height: 100vh;
  background-image: url("../img/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.containerUser {
  width: 100%;
  min-height: 100vh;
  background-image: url("../img/FONDO-01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.contentForm {
  /*background: red;*/
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  font-family: "Roboto", sans-serif;
}

.contentFormCompany {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  font-family: "Roboto", sans-serif;
}



@media only screen and (max-height: 750px) {
  .contentFormCompany {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    font-family: "Roboto", sans-serif;
  }
}

@media only screen and (max-height: 660px) {
  .contentFormCompany {
    height: auto;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    font-family: "Roboto", sans-serif;
  }


}

@media only screen and (max-height: 550px) {
  .contentFormCompany {
    height: auto;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    font-family: "Roboto", sans-serif;
  }


}

.formInput {
  border-radius: 5px;
  color: black;
  margin: 0 auto;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: 250px;
  height: 42px;
  font-family: "Roboto", sans-serif !important;
  text-decoration-color: black;
}

/*.iconPositionInput{
    color: grey;
    top: 0.7rem !important;
    font-size: 1.4rem !important;
}*/

.checkBoxRemainder {
  text-align: left;
  margin-left: 10%;
  margin-top: -18px;
}

.checkBoxRemainder span {
  color: white;
  font-size: 8pt !important;
  font-family: "Roboto", sans-serif;
}

.checkbox-orange[type="checkbox"].filled-in:checked+label:after {
  border: 2px solid #ff9800 !important;
  background-color: #ff9800 !important;
}

.input-field {
  border-bottom: none !important;
}

/*
.select-field {
    margin:20px auto 40px auto;	
    border:1px solid #d9d9d9;
    height:230px;
    overflow: hidden;
    width: 230px;
    position:relative;
 }
 */

select {
  background: transparent;
  border: none;
  font-size: 14px;
  height: 30px;
  padding: 5px;
  width: 250px;
}

/* select:focus{ outline: none;} */

/*
 .select-field::after{
     content:"\025be";
     display:table-cell;
     padding-top:7px;
     text-align:center;
     width:30px;
     height:30px;
     background-color:#d9d9d9;
     position:absolute;
     top:0;
     right:0px;	
     pointer-events: none;
 }
*/

[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
  border-color: white;
  border-radius: 5px;
  background-color: white !important;
  box-shadow: 2px 2px 2px gray;
}

.btnFromRegisterCreate {
  background-color: #ffa000;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: none !important;
  width: 100px;
}

#btnFromLoginRU {
  margin-left: 5%;
  width: 100px !important;
}

#btnFromGrups {

  width: 100px !important;
}

.btnFromRegisterCancel {
  background-color: #99a3a4;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: none !important;
  width: 100px;
}

.registerDiv {
  margin-top: 25px;
}

.grettingsDiv {
  margin-bottom: 12%;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
}

.grettingsDiv :first-child {
  font-size: 20pt;
  font-weight: 700;
  display: block;
}

.grettingsDiv :last-child {
  margin-top: -5px;
  font-size: 8pt;
  font-weight: 200;
  display: block;
}

.logoAppDiv {
  height: 120px;
  width: 100%;
  background-color: transparent;
  margin-bottom: 25%;
  background-image: url("../img/DRIVELOG.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
}

input[type="text"] {
  border-bottom: none !important;
  outline: none !important;
}