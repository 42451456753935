.sectionCardsMap {
  width: 70%;
  height: 100%;
  position: relative;
  min-height: 75vh;
  overflow: hidden;
  background-image: url("../img/FONDO-01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.fatCardMap {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cdesing {
  margin: 5px 10px 5px 10px;
  border-radius: 12px;
  background-color: #ffffff;
  max-width: 100%;
  max-height: 145px;
}

.cdMessgaeDesing {
  margin: 5px 0px 5px 5px;
  border-radius: 12px;
  background-color: #ffffff;
  max-width: 100%;
  max-height: 50px;
  position: relative;
  top: 43px;
}

.dataInfoCardrw {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.pad15 {
  padding: 15px !important;
}

.colFlex {
  display: flex;

  flex-direction: column;
}

.colFlex3 {
  display: flex;
  width: 30%;
  flex-direction: column;
}

.colFlex50 {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.colFlex40 {
  display: flex;
  width: 40%;
  flex-direction: column;
}

.colFlex30 {
  display: flex;
  width: 30%;
  flex-direction: column;
}

.colFlex200 {
  display: flex;
  width: 100%;
  height: 45px;
  flex-direction: column;
  /*  background-color: red; */


}

.colFlex20 {
  display: flex;
  width: 20%;
  flex-direction: column;
}

.colFlex25 {
  display: flex;
  width: 25%;
  flex-direction: column;
}

.sizeDestino {
  width: 200px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text11 {
  font-size: 11px;
  color: black !important;
}

@media only screen and (max-width: 995px) {
  .sectionCardsMap {
    width: 100vw;
    height: 45vh;
    position: relative;
    overflow: hidden;

    background-repeat: no-repeat;
    background-size: cover;
  }

  .contentMap {
    display: flex;
    flex-direction: column;
    min-height: 95vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .mapSize {
    width: 100vw;
    height: 50vh;
    position: relative;
    overflow: hidden;
  }
}

.cardtextColorInformation {
  color: black !important;
}