.divsListOperators {
  max-height: 70vh;
  min-height: 70vh;
  border-radius: 12px;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
  overflow-y: scroll;
}

.contentDivs {
  display: flex;
  justify-content: space-between;
}

.btnActReg-center {
  text-align: center;
}

.imgCenterOper {
  position: relative;
  top: 15px;
  width: 15% !important;
}
.textNameOpercenter {
  position: relative;
  top: 31px;
}
.textNameLeftOperAsig {
  text-align: left;
}

.selectedOpe {
  border: 2px solid orange;
  border-radius: 12px;
}

.opeFree {
  background-color: white !important;
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  top: 10px;
  left: 5px;
}

.opeAsig {
  background-color: white !important;
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  top: 10px;
  left: 5px;
  margin-left: -99%;
}

.allSelected {
  background-color: white !important;
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  top: 10px;
  left: 5px;
}

.allSelectedAsigned {
  background-color: white !important;
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  top: 10px;
  left: 5px;
}

.select_AllDiv {
  display: flex;
  justify-content: start;
  flex-direction: row;
  /* background-color: red; */
  width: 100%;
  height: 30px;
}
